@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;

  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

img {
  width: 100%;
  object-fit: cover;
}

dl,
ol,
ul {
  margin-bottom: 0.5rem;
}

:root {
  --color-bg: #ffffff;
  --color-white: #fff;
  --color-black: #1c1c1c;
  --color-red: #ff5b5b;
  --primary-white: #f8f9fb;
  --border-color: #e7eaf0;
  --border-primary: #d3dae6;
  --category-text: #596784;
  --location-bg: #1d1d1d;
  --button-yellow: #fff6a8;
  --button-orange: #ffb582;
  --green-color: #3cd210;
  --color-grey: #a4a4a4;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", "Inter", sans-serif;
  background-color: var(--color-bg);
  line-height: 1.7;
}

section {
  padding-top: 120px;
  padding-bottom: 190px;
}

a {
  text-decoration: none;
  color: var(--color-black);
}

a:hover {
  text-decoration: none;
  color: var(--color-black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.color-red {
  color: var(--color-red);
}

/* styles.css */
.color-black {
  color: var(--color-black);
}

.color-white {
  color: var(--color-white) !important;
}

.margin_twofourty {
  margin-top: 240px;
}

.link-hover-red:hover {
  color: var(--color-red);
}

.desktop_change {
  position: absolute;
  width: 100%;
  padding-top: 30px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.regular {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.heavy {
  font-weight: 800;
}

.light {
  font-weight: 200;
}

/* Loader Container */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--color-red);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.flex_start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column_direction {
  display: flex;
  flex-direction: column;
}

.position-relative {
  position: relative;
}

.input_img {
  width: 392px;
}

.nav-link.active {
  color: var(--color-red);
}

.navbar_links.active {
  color: var(--color-red);
}

/* /////////////////////////////////Navbar////////////////////// */
.desktop {
  z-index: 100;
  position: absolute;
  width: 100%;
  padding-top: 30px;
}

.mobile {
  display: none;
}

.cart1 {
  background: var(--color-bg);
}

.space_between {
  justify-content: space-between;
}

.navbar_links {
  font-size: 16px;
  line-height: 21px;
  color: var(--color-white);
  text-align: left;
  text-transform: uppercase;
  padding: 0;
}

.nav-link {
  font-size: 16px;
  line-height: 21px;
  color: var(--color-black);
  text-align: left;
  text-transform: uppercase;
  padding: 0;
}

.gap_links_btween {
  gap: 22px;
}

.links_gap {
  gap: 31px;
}

/* /////////////////////////////Footer Section////////////////////////////////*/
.footer {
  background: var(--primary-white);
  overflow: hidden;
  padding-top: 120px;
}

.footer_heading {
  margin-bottom: 36px;

  font-size: 40px;
  line-height: 86px;
  text-align: left;
  color: var(--color-black);
}

.company_logo {
  width: 89px;
}

.links_anchor {
  margin-bottom: 21px;

  font-size: 22px;
  line-height: 30px;
  text-align: left;
  font-weight: 200;
}

.contact_btn {
  padding: 20px 35px;
  border-radius: 15px;
  background: var(--color-black);

  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-bg);
}

.container-fluid {
  position: relative;
}

.gap_footer {
  gap: 156px;
}

.downapp_heading {
  margin-bottom: 28px;

  font-size: 25px;
  line-height: 86px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-black);
}

.footer_absolute {
  position: absolute;
  right: 0px;
  bottom: 0px;

  width: 100%;
}

.btn_download {
  padding: 20px 22px;
  border: 1.5px solid var(--color-black);
  gap: 14px;
  background-color: var(--color-bg);
  border-radius: 15px;
}

.gap_between_btn {
  gap: 12.5px;
}

.no_margin {
  margin: 0;
}

.margin_seventy {
  margin-top: 70px;
}

.footer_content {
  font-size: 15px;
  line-height: 86px;
  text-align: left;
  color: var(--color-black);
}

.footer_margin {
  margin-top: 154px;
}

/* ///////////////////////////HOME PAGE/////////////////////////////// */

/* ----------------Landing Page------------------- */

.hero_heading {
  font-size: 80px;
  text-align: left;
  text-transform: uppercase;
  line-height: 80px;
  color: var(--color-black);
}

.hero_paragraph {
  font-size: 26px;
  text-align: left;
  font-weight: 200;
  line-height: 39px;
  color: var(--color-black);
  margin-bottom: 53px;
}

.start_btn {
  background: var(--color-red);
  text-align: center;

  padding: 20px 30px;

  letter-spacing: 0.05em;
  line-height: 21px;
  text-align: center;
  border-radius: 15px;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--color-white);
}

.started_btn {
  background: var(--color-black);
  text-align: center;

  padding: 20px 30px;

  letter-spacing: 0.05em;
  line-height: 21px;
  text-align: center;
  border-radius: 15px;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--color-white);
}

.explore_btn {
  border-radius: 15px;

  border: 1.5px solid var(--color-black);
  padding: 20px 30px;
  background-color: var(--color-bg);

  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--color-black);
  text-transform: uppercase;
}

.btn-gap {
  gap: 33.5px;
}

/* -------------How it works------------------ */

.works_heading {
  font-size: 120px;
  line-height: 86px;
  color: var(--color-black);
  text-align: center;
  text-transform: uppercase;
}

.works_subheading {
  position: absolute;
  font-family: "Northwell";
  font-size: 40px;
  text-align: center;
  line-height: 39px;
  color: var(--color-black);
  transform: rotate(-20deg);
  top: -91px;
  right: 42.5%;
}

.meal_box {
  width: 104px;
  height: 104px;
  border-radius: 50%;
  background: var(--primary-white);
  box-shadow: inset 0 3px 6px rgba(230, 232, 238, 0.5);
  margin-bottom: 43px;
}

.load_more {
  font-size: 16px;
  height: 62px;
  width: 100%;
  background: var(--color-black);
  color: var(--color-white);
  border-radius: 18px;
  letter-spacing: 0.05em;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
}

.meal_img {
  height: 47px;
  width: 47px;
}

.meal_heading {
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 14px;
  color: var(--color-black);
}

.meal_paragraph {
  padding: 0 60px;

  font-size: 16px;
  text-align: center;
  line-height: 23px;
  color: var(--color-black);
}

.margin_top {
  margin-top: 84px;
}

.img_margin {
  margin-top: 157px;
}

/* ---------------Choose section -------------------- */
.choose {
  overflow-x: hidden;
}

.margin_fourtytwo {
  margin-bottom: 42px;
}

.dishes_absolute {
  z-index: -1;
  position: absolute;
  bottom: 0;
  right: 41px;
  transform: scale(1.1);
}

.choose_heading1 {
  font-size: 80px;
  line-height: 80px;
  color: var(--color-black);
  text-align: left;
  text-transform: uppercase;
}

.choose_paragraph {
  font-size: 26px;
  line-height: 39px;
  color: var(--color-black);
  text-align: left;
  font-weight: 200;
}

.learn_btn {
  padding: 20px 30px;
  background-color: var(--color-black);
  text-align: center;

  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 21px;
  border-radius: 15px;
  color: var(--color-white);
}

/* -------------Menu-------------- */
.banger {
  position: absolute;
  width: 100%;
  max-width: 83px;
  height: auto;
  top: 12%;
  left: 91%;
  transform: translateX(-50%);
}

@media (max-width: 992px) {
  .banger {
    width: 50%;
    max-width: 83px;
    height: auto;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }

  .category_img {
    width: 100%;
    max-width: 100%;
    height: auto;
    top: -40%;
    left: 0;
  }
}

.margin_onetwenty {
  margin-top: 120px;
}

.menu_heading {
  font-size: 120px;
  text-align: left;
  line-height: 86px;
  text-transform: uppercase;
  color: var(--color-black);
}

.menu_subheading {
  position: absolute;
  line-height: 39px;
  color: var(--color-black);
  font-family: "Northwell";
  font-size: 40px;
  top: -78px;
  transform: rotate(-10deg);
}

.menu_subheading2 {
  font-size: 30px;
  line-height: 86px;
  text-align: left;
  text-transform: uppercase;
}

.menu_box {
  height: 400px;

  max-width: 100%;
  width: 100%;

  padding: 30px;
  border-radius: 40px;
  background-color: var(--primary-white);
  border: 1.5px solid var(--border-color);
}

.category_heading {
  font-size: 22px;
  text-align: center;
  line-height: 30px;
  color: var(--color-black);
  padding-top: 95px;
  margin-bottom: 17px;
}

.menu_description {
  word-wrap: break-word;

  white-space: normal;

  max-height: 120px;
  overflow: hidden;
  font-family: "Inter";
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--category-text);
}

.menu_caleories {
  margin-top: 14px;
  font-family: "Inter";
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--color-black);
}

.category_img {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: auto;
  top: -42%;
  left: 0;
}

.price {
  font-size: 35px;
  line-height: 47px;
  text-align: left;
}

.add_remove_btn {
  width: 86px;
  height: 41px;
  background: var(--color-bg);
  border-radius: 10px;
  border: 1px solid var(--border-primary);
  gap: 12px;
}

.count_btn {
  color: var(--border-primary);
  background: var(--color-bg);
}

.count {
  font-size: 16px;

  line-height: 21px;
  text-align: center;
}

.addition {
  width: 44px;
  height: 44px;
  background: var(--color-red);
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.gap_btn {
  gap: 11px;
}

.gap_between {
  gap: 30px;
}

.caleories_box {
  height: 35px;
  width: 100%;
  background: var(--color-bg);
  text-align: center;
  border-radius: 25px;

  margin-bottom: 28px;
}

.scroll {
  overflow: scroll;
}

.scroll_btn {
  height: 62px;
  background: var(--primary-white);
  border: 1.5px solid var(--border-color);

  padding: 26px;

  border-radius: 10px;
}

.margin_scroll {
  margin-top: 62px;
}

.scroll_gap {
  gap: 22px;
}

.right-arrow {
  transform: scaleX(-1);
}

.see_btn {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-bg);

  padding: 20px 25px;
  background: var(--color-black);
  border-radius: 10px;
  margin-left: 40px;
}

/* --------------------Reward section------------------------ */
.carousel-dot {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.download_gap {
  gap: 16px;
}

.carousel-dot li {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #d8d8d8;
  opacity: 0.5;
  cursor: pointer;
}

.carousel-dot li.active {
  opacity: 1;
  background-color: var(--color-red);
}

.reward_heading {
  font-size: 80px;
  line-height: 80px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-black);
}

.reward_img {
  height: 60px;
  width: 60px;
}

.reward_gap {
  gap: 24px;
}

.reward_paragraph {
  margin-top: 26px;

  font-size: 26px;
  line-height: 39px;
  font-weight: 200;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 26px;
}

.icon_reward {
  width: 28px;
  height: 28px;
}

.reward_list {
  font-size: 18px;
  line-height: 38px;
  text-align: left;
  color: var(--color-black);
  gap: 34.5px;
  margin-bottom: 20px;
}

.align_center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.play_btn {
  height: 62px;
  border: 1.5px solid var(--color-black);
  border-radius: 15px;
  background: var(--color-bg);
  padding: 20px;
  gap: 14px;
  text-transform: uppercase;
}

.play_img {
  width: 18px;
  height: 21px;
}

.download_heading {
  font-size: 14px;
  text-align: left;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--color-black);
}

.down_gap {
  gap: 17px;
}

.gap_align {
  gap: 63px;
}

.margin_eighty {
  margin-top: 80px;
}

.policy_heading {
  font-size: 16px;
  text-align: left;
  line-height: 19px;
  color: var(--color-black);
}

.padding_policy {
  padding-right: 64px;
}

.trademark {
  font-size: 26px;
  position: absolute;
  font-size: 26px;

  left: 78%;
  top: 68%;
}

.align_end {
  display: flex;
  align-items: end;
  justify-content: center;
  overflow: hidden;
}

/* --------------review section--------------- */

.review {
  overflow: hidden;
}

.combo_2 {
  width: auto;
  z-index: -1;
  display: block;
  position: absolute;
  left: -29%;
  top: -13%;
}

.combo_1 {
  width: auto;
  position: absolute;
  left: 88%;
  top: -13%;
}

.review_heading {
  font-size: 80px;
  text-align: center;
  line-height: 80px;
  text-transform: uppercase;
  color: var(--color-black);
  margin-bottom: 64px;
}

.client_name {
  font-size: 30px;
  text-align: center;
  line-height: 86px;
}

.client_review {
  margin-top: 40px;

  font-size: 26px;
  font-weight: 200;
  line-height: 39px;
  text-align: center;
  color: var(--color-black);
}

.rating {
  width: 26px;
  height: 26px;
}

.review_scroll {
  width: 69px;
  height: 62px;
  border-radius: 15px;
  background: var(--primary-white);
  padding: 20px;

  border: 1.5px solid #e7eaef;
}

.carousel-buttons {
  display: flex;
  justify-content: center;
}

.carousel-buttons button {
  margin: auto 0;
}

/* -----------------Geo location------------------- */

.location_auto {
  margin: 90px auto;
}

.location {
  background-color: var(--location-bg);
  overflow: hidden;
  padding: 0;
}

.location_street {
  font-size: 80px;
  color: var(--color-bg);
  text-transform: uppercase;
  line-height: 86px;
  text-align: left;
  padding-right: 125px;
  margin-bottom: 66px;
}

.address_sub {
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-white);
}

.address_detail {
  font-size: 22px;
  font-weight: 200;
  color: var(--color-bg);
  line-height: 30px;
}

.gap_address {
  gap: 35px;
}

table {
  border-collapse: collapse;
  color: var(--color-bg);
}

table,
th,
td {
  border: none;
}

th,
td {
  padding: 10px;
  text-align: left;
}

.padding_contact {
  padding-bottom: 57px;
}

.cart2 {
  background: var(--color-black);
}

.contact_box {
  padding-bottom: 57px;
}

.google_location {
  width: 508px;
}

.pin_btn {
  padding: 23px 29px;
  background: var(--color-red);

  border-radius: 15px;
}

.phone_btn {
  padding: 18px 20px 18px 23px;
  background: var(--location-bg);
  border: 1.5px solid var(--color-red);
  border-radius: 15px;
}

.gap_location {
  gap: 36.2px;
}

.black_btn {
  display: flex;
  align-items: center;
  height: 62px;
  border-radius: 15px;
  background: var(--color-bg);
  border: 1.5px solid var(--border-color);
  padding: 23px;
}

.hr_location_btn {
  text-align: center;
  padding: 19px 30px 20px 25px;
  height: 62px;
  border-radius: 15px;
  background: var(--color-red);

  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--color-white);
}

.margin_onefifty {
  margin-top: 150px;
}

.margin_oneeigty {
  margin-top: 180px;
}

.gap_arrow_btn {
  gap: 47px;
}

.gap_different {
  gap: 99px;
}

.location_shop {
  position: absolute;
  right: -3%;
  top: -72px;
  width: auto;
}

.direction_img1 {
  width: 10px;
}

.direction_img {
  width: 19px;
  height: 19px;
}

.banner_mobile {
  display: none;
}

.reward {
  background: var(--primary-white);
  background-size: cover;
}

.none {
  display: none;
}

.dot_carousel {
  display: none;
}

.copywrite_bg {
  display: none;
}

/* ///////////////////////////Menu Page/////////////////////////// */

/* ---------------Landing Page--------------------- */
.content-container {
  display: flex;
  position: relative;
  height: 100%;
}

.sidebar-container {
  width: 500px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -174px;
  transition: left 0.3s ease-in-out;
  z-index: -1;
}

.slide-in .sidebar-container {
  left: 0;
}

.meal-boxes-container {
  flex-grow: 1;
  transition: margin 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin-left: 0;
  transform: translateX(0);
}

.sidebar-open .meal-boxes-container {
  margin-left: 300px;
  transform: translateX(88px);
}

.sidebar-open .meal-boxes-container .col-lg-3 {
  width: 25%;
}

.aside_section {
  height: 100%;
  border-radius: 0 35px 35px 0;

  padding: 90px;
  border-right: 2px solid var(--border-color);
  background-color: var(--primary-white);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100vw);
}

.slide-in {
  z-index: 1;
  transform: translateX(0);
}

.sidebar-visible {
  transform: translateX(0);
}

.menu_banner {
  background: var(--color-black);
}

.menu_landing-heading1 {
  margin-top: 104px;

  font-size: 80px;
  line-height: 86px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-bg);
}

.menu_landing-heading2 {
  font-size: 20px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-bg);
  line-height: 30px;
}

.margin_filter {
  margin-top: 52px;
}

.menu_filterbtn.active {
  background: #ff5b5b;
  opacity: 100%;
}

.menu_filterbtn {
  opacity: 50%;
  background-color: #0c0c0c;
  gap: 8px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-bg);
  height: 47px;
  border-radius: 15px;
  border: none;
  padding: 3px 15px;
}

.mobile_filterbtn {
  background-color: var(--color-red);
  margin-bottom: 24px;
  text-transform: uppercase;
  color: var(--color-bg);
  height: 47px;
  border-radius: 15px;
  border: none;
  padding: 3px 15px;
}

.search_btn {
  width: 423px;
  opacity: 50%;
  background-color: #0c0c0c;
  gap: 5px;

  font-size: 16px;
  line-height: 86px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-bg);
  height: 47px;
  border-radius: 15px;
  border: none;
  padding: 3px 15px;
  border: 1.5px solid var(--color-red);
  background: var(--color-red);
}

.search_img {
  width: 12px;
}

.search_btn.active {
  opacity: 100%;
}

.dropdown {
  margin-bottom: 21px;
}

button:focus {
  outline: none;
}

.dropdown-btn {
  border-bottom: 0.5px solid #818181;

  font-size: 22px;
  line-height: 30px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-black);
  background: var(--primary-white);
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.label_checkbox {
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-black);
}

.margin_checkbox {
  margin-right: 7px;
}

.type-btn {
  border-bottom: 0.5px solid #818181;

  font-size: 22px;
  line-height: 30px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-black);
  background: var(--primary-white);
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.sort-heading {
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  text-align: left;
  color: var(--color-red);
}

.checkbox-list {
  margin-top: 17px;
}

.type-margin {
  margin-top: 75px;

  display: flex;
  flex-wrap: wrap;

  flex-direction: column;
}

.icon {
  width: 13px;
  height: 9px;
}

.margin_mainbox {
  margin-bottom: 100px;
}

/* ///////////////////////////////////////Location Page//////////////////////////////////////// */

.location_section {
  background: var(--color-black);
}

.schedule_btn.active,
.location_btn.active {
  background: var(--color-black);
  border: none;
  font-weight: 800;
  color: var(--color-red);
}

.schedule_btn,
.location_btn {
  font-size: 16px;
  padding: 10px;
  text-transform: uppercase;

  text-align: center;
  border-radius: 15px;
  background: #0c0c0c;
  color: var(--color-white);
}

.location_btn-gap {
  gap: 8px;
}

.location_heading {
  font-size: 40px;
  line-height: 86px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-red);
}

.t_heading {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-bg);
}

.direction_btn,
.location_contactbtn {
  height: 62px;
  gap: 16px;
  padding: 22px;
  border-radius: 15px;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
}

.direction_btn {
  background: var(--color-red);
  color: var(--color-white);
}

.location_contactbtn {
  background: var(--color-black);
  color: var(--color-red);
  border: 1.5px solid var(--color-red) !important;
}

.margin_locationbtn {
  margin-top: 44.5px;
}

.margin_bottom {
  margin-bottom: 9px;
}

.location_box {
  border-radius: 60px;
  background: #1d1d1d;

  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
}

.padding_locationbox {
  padding: 0px 24px 103px 24px;
}

.mission_heading {
  font-size: 40px;
  line-height: 86px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-red);
}

.width_btn {
  width: 238px;
}

.mission_box {
  border-radius: 60px;
  background: #1d1d1d;

  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
}

.padding_mission {
  padding: 48px 111px 104px 77px;
}

/* ////////////////////////////////////////Choose Page/////////////////////// */

.choose_section {
  background: var(--primary-white);
  overflow: hidden;
}

.reward_section {
  background: var(--primary-white);
  overflow: hidden;
}

.ingredients_heading {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: var(--color-black);
  text-align: left;
}

.ingredients_paragraph {
  margin-top: 53px;

  font-size: 26px;
  line-height: 39px;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 217px;
}

.gradient_section {
  overflow: hidden;
}

.plate_img {
  position: absolute;
  bottom: 49%;
  left: 33%;

  z-index: -1;
}

.item_heading {
  font-size: 50px;
  line-height: 80px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-black);
}

.item_paragraph {
  line-height: 39px;
  font-size: 26px;
  text-align: left;
  color: var(--color-black);
}

.item_margin {
  margin-bottom: 233px;
}

.plate_two-img {
  position: absolute;
  bottom: 5%;
  right: 41%;
  z-index: -1;
}

.service_section {
  background: var(--primary-white);
}

.service_margin {
  margin-bottom: 70px !important;
}

.gap_staff {
  gap: 104px;
}

.position_btn {
  padding: 20px;
  margin-top: 69px;
  border-radius: 15px;
  background: var(--color-black);

  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: var(--color-white);
}

.drink_2 {
  width: auto;
  z-index: -1;
  display: block;
  position: absolute;
  left: -27%;
  top: -70%;
}

.drink_1 {
  z-index: -1;
  width: auto;
  position: absolute;
  left: 87%;
  top: -70%;
}

/* //////////////////////////////////Reward Page////////////////////////// */

.reward_marginheading {
  margin-bottom: 33.5px;
}

.earn_heading {
  font-size: 30px;
  line-height: 80px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-black);
  margin-bottom: 54.6px;
}

.earn_btn {
  padding: 20px;
  border-radius: 15px;
  background: linear-gradient(to right,
      var(--button-yellow),
      var(--button-orange));
  border: none;
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-black);
}

.earn_img {
  width: 56px;
  margin-bottom: 43px;
}

.earn_paragraph {
  font-size: 26px;
  line-height: 39px;
  color: var(--color-black);
  text-align: center;
  font-weight: 200;
}

.list_heading {
  font-size: 80px;
  text-align: center;
  line-height: 80px;
  text-transform: uppercase;
  color: var(--color-black);
  margin-bottom: 132px;
}

.container_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon_img {
  width: 28px;
}

.earn_item {
  margin: 0;
  font-size: 18px;
  line-height: 39px;
  text-align: left;
  color: var(--color-black);
}

.list_subheading {
  text-transform: uppercase;
  margin-bottom: 21px;
  font-size: 20px;
  line-height: 80px;
  text-align: left;
  color: var(--color-black);
}

.earn_box {
  margin-bottom: 31px;
  padding: 53px;
  border-radius: 18px;
  background: var(--primary-white);
  border: 1px solid var(--border-color);
}

.margin_earnbox {
  margin-bottom: 100px;
}

.mobile_side_nav {
  padding: 100px 50px;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);
  z-index: 1000;
  transition: right 0.3s ease-in-out;
}

.mobile_side_nav.open {
  right: 0;
}

.background_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.cart_heading {
  font-size: 40px;
  line-height: 60px;
  text-align: left;
  color: var(--color-black);
}

.cart_paragraph {
  margin-top: 34px;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: var(--color-black);
}

.calories {
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: #787878;
  margin-top: 16px;
  margin-bottom: 36px;
}

.cart_img {
  position: relative;

  z-index: -1;
  transform: scale(1.8);
}

.close_btn {
  position: absolute;
  background: var(--color-bg);
  top: 8%;
  right: 0;
}

.single-product {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

body.single-product-open {
  overflow: hidden;
}

/* /////////////////////////////////////////Career page////////////////////////////// */

.career_heading {
  text-transform: uppercase;
  font-size: 70px;
  line-height: 70px;
  color: var(--color-black);
  text-align: left;
  margin-bottom: 29px;
}

.career_input {
  text-transform: uppercase;
  width: 100%;
  height: 71px;
  border-radius: 15px;
  background: var(--primary-white);
  border: 1.5px solid var(--border-color) !important;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--color-black);
  padding: 25px;
}

.dropdown-content {
  width: 100%;
  border: 1.5px solid var(--border-color) !important;
  left: 2px;
  position: absolute;
  top: 72px;
  border-radius: 18px;
  background: var(--primary-white);
  padding: 11px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.dropdown-open .dropdown-content {
  height: auto;
  opacity: 1;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.drop_img {
  width: 14px;
}

.dropbtn {
  user-select: none;
  opacity: 65%;
  background: transparent;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--color-black);
}

.transparent {
  background: transparent;
  border: none;
}

.drop_style {
  opacity: 65%;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--color-black);
}

.cursor {
  position: relative;
  cursor: pointer;
}

.file-input {
  display: none;
}

.custom-file-upload {
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  height: 71px;
  border-radius: 15px;
  background: var(--primary-white);
  border: 1.5px solid var(--border-color) !important;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--color-black);
  padding: 25px;
}

.upload_img {
  width: 33px;
}

.text_height {
  height: 238px;
}

.available_heading {
  font-size: 16px;
  line-height: 39px;
  text-align: left;
  text-transform: uppercase;
  color: var(--green-color);
}

.full_heading {
  margin-top: 29px;
  font-size: 16px;
  line-height: 39px;
  text-align: right;
  text-transform: uppercase;
}

.avail_span {
  font-size: 50px;
}

.service-heading {
  font-size: 20px;

  text-align: left;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.service_detail {
  font-size: 14px;

  text-align: left;
  color: var(--color-grey);
}

.service_dot {
  border-radius: 50%;
  box-shadow: 0 3px 40px 0 var(--green-color);
  background: var(--green-color);
  width: 16px;
  height: 16px;
}

.service_box {
  width: 100%;
  height: 94px;
  cursor: pointer;
  background: #343434;
  border-radius: 18px;
  padding: 30px;
}

.service_img {
  width: 27px;
}

.background-box {
  background: var(--color-black);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.inner_shadow {
  box-shadow: inset 0 3px 10px #d0d9e9;
}

.padding_box {
  padding: 70px;
}

/* ///////////////////////////////////////////////////Job section////////////////////// */

.job_heading {
  font-size: 50px;
  line-height: 39px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-white);
}

.job_bg {
  background: var(--color-black);
}

.job_detail {
  font-size: 18px;
  line-height: 39px;
  text-align: left;
  color: var(--color-grey);
}

.line {
  background-color: #2d2d2d;
  width: 100%;
}

.role_heading {
  font-size: 26px;
  line-height: 39px;
  text-align: left;
  color: var(--color-white);
}

.role_description {
  font-size: 18px;
  line-height: 39px;
  text-align: left;
  color: var(--color-white);
}

.padding_list {
  padding: 18px;
}

.close {
  width: 24px;
}

.faq_box {
  padding: 35px;
  cursor: pointer;
  border-radius: 18px;
  background: var(--primary-white);
  border: 1.5px solid var(--border-color);
}

.job_bg {
  display: none;
}

.show-job-bg {
  display: block;
}

.job-list-item {
  cursor: pointer;
}

.rotated .icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.compartment {
  background: var(--primary-white);
  border: 1.5px solid var(--border-color);
  border-radius: 18px;
}

.paragraph-container {
  padding: 35px;
  border-radius: 18px;
  background: var(--primary-white);
  border: 1.5px solid var(--border-color);
  margin-top: 10px;
}

.faq-paragraph {
  margin: 0;
  font-size: 26px;
  color: var(--color-black);
  line-height: 39px;
  text-align: left;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.job-popup {
  position: fixed;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-black);

  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-height: 80vh;
  overflow-y: auto;
  width: 70%;
  padding: 100px 0px;

  overflow-y: auto;
}

.red_btn {
  display: flex;
  align-items: center;
  height: 62px;
  width: 69px;
  border-radius: 15px;
  background: var(--color-red);
  border: 1.5px solid var(--color-red);
  padding: 23px;
}

.contact_close {
  position: absolute;
  background: var(--color-bg);
  top: -2%;
  right: -81px;
}

.z-index {
  position: relative;
  z-index: 100;
}

.contact_bg {
  background: var(--color-bg);
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9999;
  height: 100vh;
}

.search_bg {
  padding-top: 120px;
  width: 100%;
  background: var(--color-black);
  overflow-y: scroll;
  position: fixed;
  top: -100%;
  left: 0;
  height: 100%;
  z-index: 9999;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.3s, top 0.3s;
}

.search_bg.visible {
  opacity: 1;
  top: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.search_bg.visible {
  animation: fadeIn 0.3s ease-in-out;
}

.search_input {
  background: var(--color-black);

  text-align: left;
  font-size: 120px;
  width: 100%;
  text-transform: uppercase;
  border-bottom: 1.5px solid var(--color-red);
}

.search_input::placeholder {
  color: var(--color-red);
}

.search_close {
  position: absolute;
  background: var(--color-black);
  top: -3%;
  right: -62px;
}

.search_container {
  width: 100%;
  max-width: 100%;
}

.padding_bottom {
  padding-bottom: 0;
}

.label_checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.margin_checkbox {
  margin-right: 8px;

  opacity: 0;
  position: absolute;
}

.custom_checkbox {
  width: 16px;
  height: 16px;
  border: 1.5px solid var(--color-black);
  border-radius: 4px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-color: transparent;
}

.margin_checkbox:checked+.custom_checkbox::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-red);
  border-radius: 4px;
}

.margin_checkbox:checked+.custom_checkbox::after {
  content: "\2713";
  font-size: 10px;
  font-weight: 800;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-bg);
}

.dropdown-btn:hover {
  background-color: #f5f5f5;
}

.dropdown-btn.active {
  border-bottom-color: var(--color-red);
  color: var(--color-red);
}

.mobile-component {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-bg);
  z-index: 999;
  overflow: auto;
}

.mobile_bar {
  display: none;
}

.faq_question {
  line-height: 39px;
  font-size: 26px;
  text-align: left;
  color: var(--color-black);
}

.meal-box-container {
  width: 100%;
}

.social_icon {
  width: 34px;
}

.meal_category {
  height: auto;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: -38%;
  width: 100%;
}

@media (max-width: 768px) {
  .sidebar-open .meal-boxes-container {
    margin-left: 0;
    transform: none;
  }

  .sidebar-container {
    display: none;
  }

  .padding_zero {
    padding: 0;
  }

  .mobile_img {
    margin-bottom: 45px;
  }

  .faq_section {
    background: var(--primary-white);
  }

  .faq_box {
    padding: 4px;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1.5px solid var(--border-color);
  }

  section {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .menu_filterbtn.active {
    background: none;
  }

  .input_img {
    width: 236px;
  }

  .red-border {
    border: 1.5px solid var(--color-red);
  }

  .mobile_icon {
    width: 12px;
  }

  .category_img {
    top: -44%;
  }

  .search_input {
    font-size: 31px;
    line-height: 55px;
  }

  .search_close {
    position: absolute;
    top: -72px;
    right: 15px;
    width: 30px;
    z-index: 10;
  }

  .role_description {
    font-size: 16px;
    line-height: 24px;
  }

  .role_heading {
    font-size: 18px;
    line-height: 24px;
  }

  .job_heading {
    font-size: 30px;
    line-height: 31px;
  }

  .job_detail {
    font-size: 14px;
    line-height: 25px;
  }

  .faq-paragraph {
    font-size: 18px;
    line-height: 30px;
  }

  .faq_question {
    line-height: 24px;
    font-size: 18px;
  }

  .semi {
    font-weight: 600;
  }

  .padding_bar {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mobile_bar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--primary-white);
    display: block;
    z-index: 1000;

    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;

    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }

      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  .mobile_bar.appear {
    opacity: 1;
    transform: translateX(0);
    animation: slideIn 0.3s ease;
  }

  .magnify_icon {
    width: 12px;
  }

  .cart_paragraph {
    font-size: 15px;
    line-height: 19px;
    text-align: center;
  }

  .navbar_links {
    font-size: 40px;
    line-height: 55px;
  }

  .meal_btn {
    margin-bottom: 27px;
    display: flex;
    background: var(--color-red);
    text-align: center;
    padding: 20px 30px;
    letter-spacing: 0.05em;
    line-height: 21px;
    border-radius: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--color-white);
    align-items: center;
    width: 100%;
    justify-content: center;

    gap: 10px;
  }

  .sign_btn {
    display: flex;
    background: var(--color-black);
    text-align: center;
    padding: 20px 30px;
    letter-spacing: 0.05em;
    line-height: 21px;
    border-radius: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--color-red);
    align-items: center;
    width: 100%;
    border: 1.5px solid var(--color-red);
    justify-content: center;
  }

  .cart_img {
    left: 46px;
    transform: scale(1.3);
  }

  .close_btn {
    background: var(--color-black);
    top: 6%;
    right: 22px;
    width: 22px;
  }

  .calories {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  .cart_heading {
    font-size: 22px;
    text-align: center;
    line-height: 30px;
  }

  .service_dot {
    display: none;
  }

  .padding {
    padding: 0;
  }

  .career_heading {
    font-size: 60px;
    line-height: 55px;
  }

  .padding_box {
    padding: 23px;
  }

  .aside_section {
    display: none;
  }

  .desktop_change {
    display: none;
  }

  .copywrite_bg {
    display: flex;
    justify-content: center;
    background-color: var(--color-black);
  }

  .product_center {
    justify-content: center;
    align-items: center;
  }

  .start_btn {
    width: 315px;
  }

  .phone_btn {
    height: 80px;
  }

  .pin_btn {
    height: 80px;
  }

  .google_location {
    width: 200px;
    height: 172px;
    border-radius: 15px;
  }

  .none {
    display: block;
  }

  .policy_heading {
    display: none;
  }

  .explore_btn {
    width: 100%;
  }

  .started_btn {
    width: 100%;
  }

  .hero_banner {
    overflow: hidden;
  }

  .banner_mobile {
    display: block;
    position: absolute;
    z-index: -1;
    top: 23%;
    left: -28px;
    width: auto;
  }

  .column_reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .img_margin {
    margin-top: 70px;
  }

  .gap_div {
    gap: 90px;
  }

  .black_btn {
    display: none;
  }

  .gap_align {
    gap: 22px;
  }

  .download_heading {
    margin-top: 29.5px;
  }

  .hero_paragraph {
    font-size: 20px;
    padding-top: 158px;
    line-height: 27px;
  }

  .banner-image {
    display: none;
  }

  .mobile_design {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .side_btn {
    background-color: var(--color-black);
  }

  .mobile_logo {
    position: relative;
    right: 76px;
  }

  .mobile {
    display: block;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .gap_dot {
    gap: 32px;
  }

  .cart2 {
    background-color: var(--color-black);
  }

  header {
    background: var(--color-black);
  }

  .banger {
    left: 91%;
  }

  .margin_zero {
    margin: 0;
  }

  .footer_absolute {
    right: 19px;
    bottom: 3%;
    transform: scale(1.3);
  }

  .footer_padding {
    padding-bottom: 83%;
  }

  .downapp_heading {
    line-height: 19px;
  }

  .footer_heading {
    margin-bottom: -20px;
    font-size: 25px;
    line-height: 86px;
  }

  .gap_footer {
    gap: 20px;
  }

  .navbar-brand {
    grid-column: 1 / span 5;
  }

  .navbar-collapse {
    grid-column: 6 / span 8;
  }

  .hero_heading {
    margin-top: 30px;
    font-size: 60px;
    line-height: 55px;
  }

  .footer_content {
    font-size: 13px;
    line-height: 32px;
    color: #7b7b7b;
  }

  .display_none {
    display: none;
  }

  .review_heading {
    font-size: 60px;
    line-height: 55px;
    margin-bottom: 15px;
  }

  .trademark {
    left: 0;
    top: 0;
  }

  .padding_locationbox {
    padding: 0px 20px 103px 20px;
  }

  .footer_margin {
    margin-top: 40px;
  }

  .padding_mission {
    padding: 22px 20px 0px 20px;
  }

  .row_direction {
    flex-direction: row;
  }

  .flex_direction {
    display: flex;
    flex-direction: column;
  }

  .works_heading {
    font-size: 83px;
  }

  .works_subheading {
    font-size: 30px;
    top: -61px;
  }

  .client_review {
    font-size: 20px;
    margin-top: 20px;
    line-height: 27px;
  }

  .inner_shadow {
    box-shadow: none;
  }

  .menu_heading {
    font-size: 60px;
    line-height: 55px;
  }

  .menu_subheading {
    font-size: 25px;
    top: -48px;
  }

  .menu_subheading2 {
    line-height: 86px;
    font-size: 30px;
  }

  .reward_heading {
    font-size: 60px;
    line-height: 54px;
  }

  .padding_policy {
    padding: 0;
  }

  .reward_img {
    left: 72%;
    position: absolute;
    top: 55px;
  }

  .dishes_absolute {
    position: absolute;
    bottom: 0;
    right: 0px;

    transform: rotate(90deg);
  }

  .margin_mobile {
    margin-bottom: 370px;
  }

  .learn_btn {
    width: 100%;
  }

  .reward_paragraph {
    font-size: 20px;
    line-height: 27px;
  }

  .reward_list {
    font-size: 18px;
    line-height: 20px;
  }

  .meal_heading {
    font-size: 30px;
    line-height: 35px;
  }

  .meal_paragraph {
    font-size: 20px;
    text-align: center;
    line-height: 25px;
  }

  .location {
    padding: 50px 20px;
  }

  .location_shop {
    display: none;
  }

  .location_box {
    border-radius: 0px;
    box-shadow: none;
  }

  .hr_location_btn {
    font-size: 14px;
    padding: 15px 20px;
  }

  .margin_onefifty {
    margin-top: 32px;
  }

  .gap_arrow_btn {
    gap: 33px;
  }

  .gap_location {
    gap: 20px;
  }

  .align_center.scroll {
    gap: 50px;
  }

  .scroll_gap {
    gap: 10px;
  }

  .DiffLocation {
    padding: 20px;
  }

  .location_street.heavy {
    font-size: 60px;
    line-height: 55px;
    margin-bottom: 21px;
  }

  .margin_thirty {
    margin-top: 33px;
  }

  .links_anchor {
    margin-bottom: 5px;
  }

  table {
    font-size: 14px;
    line-height: 20px;
  }

  .choose_heading1 {
    font-size: 60px;
    line-height: 55px;
  }

  .choose_paragraph {
    font-size: 20px;
    line-height: 27px;
  }

  .desktop {
    display: none;
  }

  .address_sub.bold,
  .address_detail {
    font-size: 22px;
    line-height: 30px;
  }

  .padding_contact {
    padding-bottom: 13px;
  }

  .dot_carousel {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #333333;
    margin: 0 5px;
    cursor: pointer;
  }

  .actives {
    background-color: var(--color-red);
  }

  .combo_2 {
    display: none;
  }

  .comb_1 {
    display: none;
  }

  .choose_section {
    padding: 25px 0;
    background: var(--color-black);
  }

  .menu_landing-heading1 {
    font-size: 60px;
    line-height: 55px;
    padding: 25px 0px;
    color: var(--color-white) !important;
    margin: 0;
  }

  .menu_landing-heading2 {
    font-size: 20px;
    line-height: 27px;
    color: var(--color-white) !important;
  }

  .list_heading {
    font-size: 60px;
    line-height: 55px;
    margin-bottom: 40px;
  }

  .item_heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .item_paragraph {
    line-height: 27px;
    font-size: 20px;
  }

  .earn_heading {
    display: none;
  }

  .earn_paragraph {
    font-size: 20px;
    line-height: 27px;
  }

  .earn_btn {
    width: 294px;
  }

  .mobile_margin {
    margin-top: 38px;
  }

  .list_subheading {
    text-align: center;
  }

  .earn_item {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  .btn_top {
    margin-top: 18px;
  }

  .ingredients_heading {
    font-size: 60px;
    line-height: 55px;
  }

  .location_btn-gap {
    overflow: scroll;
  }

  .hero_location {
    padding: 0;
    background: var(--color-black);
  }

  .menu_filterbtn {
    line-height: 16px;
  }

  .main_section {
    padding: 150px 0px;
  }

  .ingredients_paragraph {
    margin-top: 30px;
    padding-bottom: 120px;
    font-size: 20px;
    line-height: 27px;
  }

  .item_margin {
    margin-bottom: 100px;
  }

  .plate_img {
    bottom: 57%;
    left: 47%;
    z-index: -1;
    transform: rotate(76deg) scale(1.5);
  }

  .plate_two-img {
    bottom: -13%;
    right: 38%;
    transform: rotate(-73deg) scale(1.5);
  }

  .service_margin {
    margin-bottom: 25px !important;
  }

  .text_align {
    text-align: center;
  }

  .position_btn {
    width: 345px;
    margin-top: 18px;
  }

  .drink_1 {
    width: 200px;

    left: 72%;
    top: 22%;
  }

  .drink_2 {
    width: 200px;
    top: 22%;
    left: -23%;
  }

  .location_heading {
    margin-bottom: 21px;
    font-size: 60px;
    line-height: 55px;
    color: var(--color-white);
  }

  .none {
    display: flex;
  }

  .t_heading {
    font-size: 22px;
    line-height: 25px;
  }

  .mission_heading {
    font-size: 60px;
    line-height: 55px;
    color: var(--color-white);
  }

  .mission_box {
    border-radius: 0px;
    box-shadow: none;
  }

  .mission_img {
    height: 187px;
  }
}

@media screen and (min-width: 1920px) {
  .category_img {
    top: -48%;
  }
}

@media screen and (min-width: 2400px) {
  .category_img {
    top: -60%;
  }

  .banger {
    left: 93%;
  }
}

@media screen and (min-width: 2560px) {
  .category_img {
    top: -60%;
    left: 60px;
    max-width: 80%;
  }

  .banger {
    left: 95%;
  }
}

@media screen and (min-width: 3840px) {
  .category_img {
    top: -61%;
    left: 151px;
    max-width: 63%;
  }

  .banger {
    left: 96%;
  }
}

@media screen and (min-width: 5760px) {
  .category_img {
    top: -66%;
    left: 313px;
    max-width: 44%;
  }

  .banger {
    left: 97%;
  }
}