.multiple-star {
    width: 62px;
}

.white_star {
    width: 25px;
    margin-left: 5px;
}


/* Animation for PlanPanel */
@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.plan-panel {
    gap: 240px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideInFromRight 0.5s ease-out;
}

.weight-img {
    width: 175px;
}

.cake-img {
    width: 230px;
}

.started_btn:disabled,
.started_btn.disabled {
    opacity: 0.5;

}


.weight-heading {
    font-size: 40px;
    text-align: left;
    text-transform: uppercase;
    line-height: 80px;
    color: var(--color-black);
}

.weight-input {
    border: 2px solid var(--color-black);
    border-radius: 8px;
    padding: 20px;
    width: 100%;
}

.weight-input::placeholder {
    font-size: 21px;
    text-align: left;
    font-weight: 200;
    line-height: 39px;
    color: var(--color-black);
    font-weight: 600;
}